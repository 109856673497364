import { formatDate } from 'utils/masks'
import { translateBoolean } from 'utils/functions'

const customCreatedAtRender = (value) => {
  return formatDate(value, { hideHour: true })
}

const customVisibleToOrgRender = (value) => {
  return translateBoolean(value)
}

export const columnsPresets = [
  {
    name: 'name',
    label: 'Nome',
  },
  {
    name: 'user_email',
    label: 'Autoria',
  },
  {
    name: 'visible_to_organization',
    label: 'Compartilhado com a organização',
    options: {
      customBodyRender: customVisibleToOrgRender,
    },
  },
  {
    name: 'created_at',
    label: 'Criado em',
    options: {
      customBodyRender: customCreatedAtRender,
    },
  },
  {
    name: 'action',
    label: 'Ação',
  },
]
