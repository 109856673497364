import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Badge as MUIBadge } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Icons } from 'ui/components'

import { fetchPlanServicesList as fetchPlanCpfServicesList } from 'redux/planCpfServices/actions'
import { fetchPlanServicesList as fetchPlanCnpjServicesList } from 'redux/planCnpjServices/actions'
import { fetchPlanCpfLast } from 'redux/planCpf/actions'
import { fetchPlanCnpjLast } from 'redux/planCnpj/actions'
import { getList, getListBlacklist, getListBatch } from 'services/apiMotorBgc'

import { BATCH_STATUS } from 'utils/constants/batchStatus'
import analysisStatusConstants from 'utils/constants/analysisStatus'
import serviceStatusConstants from 'utils/constants/serviceStatus'
import reportError from 'utils/errorReporter'

const Badge = withStyles({
  anchorOriginTopRightRectangle: {
    backgroundColor: '#ccc',
    right: -30,
    transform: 'scale(1) translate(50%, -10%)',
  },
})(MUIBadge)

const BADGE_MAX = 999999999

export default function ({ currentPath }) {
  const dispatch = useDispatch()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const [totalProcessing, setTotalProcessing] = useState(null)
  const [totalGeral, setTotalGeral] = useState(null)
  const [totalApproveds, setTotalApproveds] = useState(null)
  const [totalInAttentions, setTotalInAttentions] = useState(null)
  const [totalReproveds, setTotalReproveds] = useState(null)
  const [totalInexistents, setTotalInexistents] = useState(null)
  const [totalBlacklists, setTotalBlacklists] = useState(null)
  const [totalPending, setTotalPending] = useState(null)
  const [totalError, setTotalError] = useState(null)
  const [totalBatchProcessing, setTotalBatchProcessing] = useState(null)
  const [totalBatchProcessed, setTotalBatchProcessed] = useState(null)

  const listBlacklist = async () => {
    let res = null
    try {
      res = await getListBlacklist(
        apiKey,
        userId,
        panelEnv,
      )
    } catch (err) {
      reportError(err, 'Erro ao listar bloqueados')
      return
    }

    setTotalBlacklists(parseInt(res.headers['x-total'], 10))
  }

  const listDocs = async (type, options = {}) => {
    let res = null
    try {
      res = await getList(
        apiKey,
        userId,
        panelEnv,
        {
          totalsOnly: true,
          analysisStatus: options.analysisStatus,
          status: options.status,
          slowProcessing: options.slowProcessing,
          isTakingTimeToProcess: options.isTakingTimeToProcess,
          inexistent: options.inexistent,
        },
      )
    } catch (err) {
      reportError(err, `Erro ao listar ${panelEnv.toUpperCase()}s`)
      return
    }

    switch (type) {
      case 'processing': setTotalProcessing(parseInt(res.headers['x-total'], 10)); break
      case 'total_geral': setTotalGeral(parseInt(res.headers['x-total'], 10)); break
      case 'approveds': setTotalApproveds(parseInt(res.headers['x-total'], 10)); break
      case 'in_attentions': setTotalInAttentions(parseInt(res.headers['x-total'], 10)); break
      case 'reproveds': setTotalReproveds(parseInt(res.headers['x-total'], 10)); break
      case 'inexistents': setTotalInexistents(parseInt(res.headers['x-total'], 10)); break
      case 'pending': setTotalPending(parseInt(res.headers['x-total'], 10)); break
      case 'errors': setTotalError(parseInt(res.headers['x-total'], 10)); break
      default:
        break
    }
  }

  const listBatchCpfDoc = async (type, options = {}) => {
    let res = null
    try {
      res = await getListBatch(
        apiKey,
        userId,
        panelEnv,
        {
          totalsOnly: true,
          status: options.status,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao listar lotes')
      return
    }

    switch (type) {
      case 'processing': setTotalBatchProcessing(parseInt(res.headers['x-total'], 10)); break
      case 'processed': setTotalBatchProcessed(parseInt(res.headers['x-total'], 10)); break
      default:
        break
    }
  }

  const calculateTotals = () => {
    listDocs('processing', {
      status: serviceStatusConstants.SERVICE_STATUS.processing,
      isTakingTimeToProcess: false,
    })
    listDocs('total_geral', {
      analysisStatus: null,
    })
    listDocs('approveds', {
      analysisStatus: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.approved,
    })
    listDocs('in_attentions', {
      analysisStatus: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.in_attention,
    })
    listDocs('reproveds', {
      analysisStatus: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.reproved,
    })
    listDocs('inexistents', {
      inexistent: true,
    })
    listBlacklist()
    listDocs('pending', {
      status: serviceStatusConstants.SERVICE_STATUS.processing,
      isTakingTimeToProcess: true,
    })
    listDocs('errors', {
      status: serviceStatusConstants.SERVICE_STATUS.error,
    })
    listBatchCpfDoc('processing', {
      status: BATCH_STATUS.processing,
    })
    listBatchCpfDoc('processed', {
      status: BATCH_STATUS.processed,
    })
  }

  useEffect(() => {
    dispatch(fetchPlanCpfServicesList())
    dispatch(fetchPlanCnpjServicesList())
    dispatch(fetchPlanCpfLast())
    dispatch(fetchPlanCnpjLast())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTimeout(calculateTotals, 3 * 1000)

    const intervalId = setInterval(calculateTotals, 120 * 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderNavConsults = () => {
    return (
      <li>
        <div className="category">
          <Icons icon="fileText" />
          Consultas
        </div>
        <ul>
          <li>
            <Link
              className={(currentPath === `/${panelEnv}/total`) ? 'selected' : ''}
              to={`/${panelEnv}/total`}
            >
              <Badge badgeContent={totalGeral} color="primary" max={BADGE_MAX}>
                Total
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/processing`) ? 'selected' : ''}
              to={`/${panelEnv}/processing`}
            >
              <Badge badgeContent={totalProcessing} color="primary" max={BADGE_MAX}>
                Processando
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/approved`) ? 'selected' : ''}
              to={`/${panelEnv}/approved`}
            >
              <Badge badgeContent={totalApproveds} color="primary" max={BADGE_MAX}>
                Aprovados
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/in_attention`) ? 'selected' : ''}
              to={`/${panelEnv}/in_attention`}
            >
              <Badge badgeContent={totalInAttentions} color="primary" max={BADGE_MAX}>
                Em Atenção
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/reproved`) ? 'selected' : ''}
              to={`/${panelEnv}/reproved`}
            >
              <Badge badgeContent={totalReproveds} color="primary" max={BADGE_MAX}>
                Reprovados
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/inexistent`) ? 'selected' : ''}
              to={`/${panelEnv}/inexistent`}
            >
              <Badge badgeContent={totalInexistents} color="primary" max={BADGE_MAX}>
                Inexistentes
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/blocked`) ? 'selected' : ''}
              to={`/${panelEnv}/blocked`}
            >
              <Badge badgeContent={totalBlacklists} color="primary" max={BADGE_MAX}>
                Bloqueados
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/with-error`) ? 'selected' : ''}
              to={`/${panelEnv}/with-error`}
            >
              <Badge badgeContent={totalError} color="primary" max={BADGE_MAX}>
                Com Erro
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/pending`) ? 'selected' : ''}
              to={`/${panelEnv}/pending`}
            >
              <Badge badgeContent={totalPending} color="primary" max={BADGE_MAX}>
                Pendentes
              </Badge>
            </Link>
          </li>

          {/* <li><Link to="/icons-demo">Icons Demo</Link></li>
          <li><Link to="/modal-demo">Modal Demo</Link></li>
          <li><Link to="/buttons-demo">Buttons Demo</Link></li> */}

        </ul>
      </li>
    )
  }

  const renderNavBatchConsults = () => {
    return (
      <li>
        <div className="category">
          <Icons icon="fileText" />
          Consultas em Lote
        </div>
        <ul>
          <li>
            <Link
              className={(currentPath === `/${panelEnv}/batch/processing`) ? 'selected' : ''}
              to={`/${panelEnv}/batch/processing`}
            >
              <Badge badgeContent={totalBatchProcessing} color="primary" max={BADGE_MAX}>
                Processando
              </Badge>
            </Link>
          </li>

          <li>
            <Link
              className={(currentPath === `/${panelEnv}/batch/processed`) ? 'selected' : ''}
              to={`/${panelEnv}/batch/processed`}
            >
              <Badge badgeContent={totalBatchProcessed} color="primary" max={BADGE_MAX}>
                Processados
              </Badge>
            </Link>
          </li>
        </ul>
      </li>
    )
  }

  const renderNavConfigs = () => {
    return (
      <li>
        <div className="category second-category">
          <Icons icon="settings" className="icon-menu" />
          Configurações
        </div>
        <ul>
          <li>
            <Link className={(currentPath === `/${panelEnv}/plan`) ? 'selected' : ''} to={`/${panelEnv}/plan`}>
              Plano
            </Link>
          </li>
        </ul>
      </li>
    )
  }

  return (
    <nav>
      <ul>
        {renderNavConsults()}
        {renderNavBatchConsults()}
        {renderNavConfigs()}
      </ul>
    </nav>
  )
}
