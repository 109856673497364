import _ from 'lodash'
import { PANEL_ENVS } from 'utils/constants/environments'

import { objToQueryString } from 'utils/functions'
import axios from './axiosCustom'

export const HOST = process.env.REACT_APP_BGC_MOTOR_API
export const DATE_FORMAT = 'DD-MM-YYYY'

export function headers(apiKey, newHeaders = {}) {
  return {
    'x-api-key': apiKey,
    ...newHeaders,
  }
}

export function getDocNumberFromParams(panelEnv, params) {
  switch (panelEnv) {
    case (PANEL_ENVS.CPF):
      return { cpf: params.docNumber }
    case (PANEL_ENVS.CNPJ):
      return { cnpj: params.docNumber }
    default: throw new Error(`Invalid panel env: ${panelEnv}`)
  }
}

export function getDocNumber(panelEnv, docNumber, isBatch = false) {
  switch (panelEnv) {
    case (PANEL_ENVS.CPF):
      return isBatch ? { cpfs: docNumber } : { cpf: docNumber }
    case (PANEL_ENVS.CNPJ):
      return isBatch ? { cnpjs: docNumber } : { cnpj: docNumber }
    default: throw new Error(`Invalid panel env: ${panelEnv}`)
  }
}

/** List the service of the user's plan
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * @orgId {string} Defines if call plan from organization
 * */
export async function getListPlanServices(apiKey, userId, panelEnv, orgId = null) {
  const orgPath = orgId ? '/organization' : ''

  const queryString = objToQueryString({
    user_id: userId,
    org_id: orgId,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}${orgPath}/${panelEnv}/plan/services/list?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

/** Requests the user's last generated plan to the backend.
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * @orgId {string} Defines if call plan from organization
 * */
export async function getLastGeneratedPlan(apiKey, userId, panelEnv, orgId = null) {
  const orgPath = orgId ? '/organization' : ''

  const queryString = objToQueryString({
    user_id: userId,
    org_id: orgId,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}${orgPath}/${panelEnv}/plan/last_generated_plan?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

/** Create a user's plan with the services informed.
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * @orgId {string} Defines if call plan from organization
 * */
export async function postGeneratePlan(apiKey, userId, panelEnv, services, processPole, orgId = null) {
  const servicesTreated = _.map(services, service => {
    const newService = _.pick(service, ['service_id', 'type_results'])

    newService.type_results = _.map(service.type_results, typeResult => (
      _.pick(
        typeResult,
        [
          'type_result_id',
          'move_to',

          // data to processes_classifier
          'data.journal_blacklist_contains.values',
          'data.journal_blacklist_exact.values',
          'data.journal_whitelist_contains.values',
          'data.journal_whitelist_exact.values',
          'data.process_type_blacklist_contains.values',
          'data.process_type_blacklist_exact.values',
          'data.process_type_whitelist_contains.values',
          'data.process_type_whitelist_exact.values',

          // data to credit_score
          'data.minimum_score.value',

          // data to membership_board_recursive
          'data.max_depth.value',
        ],
      )
    ))

    return newService
  })

  const data = {
    user_id: userId,
    services: servicesTreated,
    process_pole: processPole,
  }

  if (orgId) data.org_id = orgId
  const orgPath = orgId ? '/organization' : ''

  const res = await axios({
    method: 'POST',
    url: `${HOST}${orgPath}/${panelEnv}/plan/generate_plan`,
    headers: headers(apiKey, { 'Content-type': 'application/json' }),
    data: data,
  })

  return res
}

/** Requests a processment to the backend.
 * @userId {string} user identifier
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * @docId {string} processment identifier
 * */
export async function getDoc(apiKey, userId, panelEnv, docId) {
  const res = await axios({
    method: 'GET',
    url: `${HOST}/${panelEnv}?user_id=${userId}&doc_id=${docId}`,
    headers: headers(apiKey),
  })

  return res
}

/** Requests to the backend a list of all processments
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * @params__docNumber {string} the cpf or cnpj of the request
 * */
export async function getList(apiKey, userId, panelEnv, params = {}) {
  const docNumber = getDocNumberFromParams(panelEnv, params)

  const {
    page,
    totalsOnly,
    analysisStatus,
    finalDate,
    initialDate,
    slowProcessing,
    inexistent,
    batchId,
    status,
    withServices,
    isTakingTimeToProcess,
  } = params

  const queryString = objToQueryString({
    user_id: userId,
    initial_date: initialDate ? initialDate.format(DATE_FORMAT) : null,
    final_date: finalDate ? finalDate.format(DATE_FORMAT) : null,
    status: status,
    analysis_status: analysisStatus,
    with_services: withServices || false,
    slow_processing: slowProcessing,
    is_taking_time_to_process: isTakingTimeToProcess,
    inexistent: inexistent,
    batch_id: batchId,
    page: page || 1,
    ...docNumber,
  })

  const res = await axios({
    method: totalsOnly ? 'HEAD' : 'GET',
    url: `${HOST}/${panelEnv}/list?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

/** Requests to the backend a list of all the batch processments
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * */
export async function getListBatch(apiKey, userId, panelEnv, params = {}) {
  const {
    page,
    perPage,
    totalsOnly,
    description,
    status,
  } = params

  const queryString = objToQueryString({
    user_id: userId,
    description: description,
    status: status,
    page: page || 1,
    per_page: perPage,
  })

  const res = await axios({
    method: totalsOnly ? 'HEAD' : 'GET',
    url: `${HOST}/${panelEnv}/batch?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

/** Requests a new processment to the backend
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * @docNumber {string} the cpf or cnpj of the request
 * @planId {string} the cpf or cnpj of the request (optional)
 * */
export async function postCreateDoc(apiKey, userId, panelEnv, docNumber, planId = null) {
  const docNumberObj = getDocNumber(panelEnv, docNumber)

  const data = {
    user_id: userId,
    ...docNumberObj,
  }
  if (planId) data.plan_id = planId

  const res = await axios({
    method: 'POST',
    url: `${HOST}/${panelEnv}/create`,
    headers: headers(apiKey, { 'Content-type': 'application/json' }),
    data: data,
  })

  return res
}

/** Requests the processment of a batch to the backend
* @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
* @docNumbers {string[]} the cpfs or cnpjs of the request
* */
export async function postCreateBatchDoc(apiKey, userId, panelEnv, description, docNumbers, planId = null) {
  const docNumbersObj = getDocNumber(panelEnv, docNumbers, true)

  const data = {
    user_id: userId,
    description: description,
    ...docNumbersObj,
  }
  if (planId) data.plan_id = planId

  const res = await axios({
    method: 'POST',
    url: `${HOST}/${panelEnv}/batch`,
    headers: headers(apiKey, { 'Content-type': 'application/json' }),
    data: data,
  })

  return res
}

/** Changes the status of an analysis */
export async function patchUpdateAnalysisStatus(
  apiKey,
  userId,
  panelEnv,
  docId,
  newAnalysisStatus,
  description,
) {
  const data = {
    user_id: userId,
    doc_id: docId,
    analysis_status: newAnalysisStatus,
    description: description,
  }

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/${panelEnv}/analysis_status`,
    headers: headers(apiKey, { 'Content-type': 'application/json' }),
    data: data,
  })

  return res
}

/** Request to backend the user's blacklist.
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * */
export async function getListBlacklist(apiKey, userId, panelEnv, params = {}) {
  const docNumber = getDocNumberFromParams(panelEnv, params)

  const queryString = objToQueryString({
    user_id: userId,
    page: params.page || 1,
    ...docNumber,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/${panelEnv}/blacklist/list?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

/** Adds a person (cpf or cnpj) to the user blacklist.
 * @userId {string} user identifier
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * @docNumber {string} cpf or cnpj digits
 * */
export async function postBlacklist(apiKey, userId, panelEnv, docNumber) {
  const docNumberObj = getDocNumber(panelEnv, docNumber)

  const data = {
    user_id: userId,
    ...docNumberObj,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/${panelEnv}/blacklist`,
    headers: headers(apiKey, { 'Content-type': 'application/json' }),
    data: data,
  })

  return res
}

/** Removes a person (cpf or cnpj) from the user blacklist.
 * @userId {string} user identifier
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * @docNumber {string} cpf or cnpj digits
 * */
export async function deleteBlacklist(apiKey, userId, panelEnv, docNumber) {
  const docNumberObj = getDocNumber(panelEnv, docNumber)

  const data = {
    user_id: userId,
    ...docNumberObj,
  }

  const res = await axios({
    method: 'DELETE',
    url: `${HOST}/${panelEnv}/blacklist`,
    headers: headers(apiKey, { 'Content-type': 'application/json' }),
    data: data,
  })

  return res
}

/** Requests the processment of a blacklist batch to the backend
* @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
* @docNumbers {string[]} the cpfs or cnpjs of the request
* */
export async function postCreateBlacklistBatch(apiKey, userId, panelEnv, description, docNumbers, params = {}) {
  const { overwrite } = params
  const docNumbersObj = getDocNumber(panelEnv, docNumbers, true)

  const data = {
    user_id: userId,
    description: description,
    overwrite: overwrite || false,
    ...docNumbersObj,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/${panelEnv}/blacklist_batch`,
    headers: headers(apiKey, { 'Content-type': 'application/json' }),
    data: data,
  })

  return res
}


/** Requests previously generated report to be exportted as a file (CSV, ZIP or PDF).
 * @userId {string} user identifier
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * */
export async function getExportReport(apiKey, userId, panelEnv, params = {}) {
  const { docId, exportType, withOnlyRequestedServices } = params

  const queryString = objToQueryString({
    user_id: userId,
    doc_id: docId,
    with_only_requested_services: withOnlyRequestedServices || false,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/${panelEnv}/export/${exportType.toLowerCase()}?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

/** Generate a report (on a date interval) to be exportted as files (CSV, ZIP or PDF).
 * @userId {string} user identifier
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * */
export async function postExportReportListByDate(apiKey, userId, panelEnv, params = {}) {
  const { exportType, initialDate, finalDate, filter } = params

  const data = {
    user_id: userId,
    initial_date: initialDate.format(DATE_FORMAT),
    final_date: finalDate.format(DATE_FORMAT),
    ...filter,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/${panelEnv}/export/${exportType.toLowerCase()}/list`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function postExportReportListByBatch(apiKey, userId, panelEnv, params = {}) {
  const { exportType, batchId, filter } = params

  const data = {
    user_id: userId,
    batch_id: batchId,
    ...filter,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/${panelEnv}/export/${exportType.toLowerCase()}/list/batch`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function getExportReportList(apiKey, userId, panelEnv, ofType, params = {}) {
  const { exportType, page } = params

  const queryString = objToQueryString({
    user_id: userId,
    of_type: ofType,
    page: page || 1,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/${panelEnv}/export/${exportType.toLowerCase()}/list?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function getExportReportItem(apiKey, userId, panelEnv, params = {}) {
  const { exportType, reportId } = params

  const queryString = objToQueryString({
    user_id: userId,
    report_id: reportId,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/${panelEnv}/export/${exportType.toLowerCase()}/list/one?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

/** Gets the list of daily analytics
 * @userId {string} user identifier
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * */
export async function getDailyAnalytics(apiKey, userId, panelEnv, params = {}) {
  const { page } = params

  const queryString = objToQueryString({
    user_id: userId,
    page: page || 1,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/${panelEnv}/analytics/list_daily?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

/** Gets the list of daily process times
 * @userId {string} user identifier
 * @panelEnv {string} panel environment ('cpf' or 'cnpj'). Defines which API will be called
 * */
export async function getDailyProcessTimeAnalytics(apiKey, userId, panelEnv, params = {}) {
  const { page } = params

  const queryString = objToQueryString({
    user_id: userId,
    page: page || 1,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/${panelEnv}/analytics/list_daily/process_time?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function getUser(apiKey, userId, params = {}) {
  const { withConfig } = params

  const queryString = objToQueryString({
    user_id: userId,
    with_config: withConfig || false,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/user?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function putUser(apiKey, userId, params = {}) {
  const { userData } = params

  const data = {
    user_id: userId,
    data: userData,
  }

  const res = await axios({
    method: 'PUT',
    url: `${HOST}/user`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function getOrganization(apiKey, userId, params = {}) {
  const { orgId, withConfig, withConfigGovBrAccount } = params

  const queryString = objToQueryString({
    user_id: userId,
    org_id: orgId,
    with_config: withConfig || false,
    with_config_gov_br_account: withConfigGovBrAccount || false,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/organization?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function getOrganizations(apiKey, userId, params = {}) {
  const { name, page } = params

  const queryString = objToQueryString({
    user_id: userId,
    name: name,
    page: page || 1,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/organization/list?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function postCreateOrganization(apiKey, userId, name) {
  const data = {
    user_id: userId,
    name: name.toLowerCase(),
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/organization`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function putOrganization(apiKey, userId, params = {}) {
  const { orgData, orgId } = params

  const data = {
    user_id: userId,
    org_id: orgId,
    data: orgData,
  }

  const res = await axios({
    method: 'PUT',
    url: `${HOST}/organization`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function postProcessesClassifierPreset(apiKey, userId, params = {}) {
  const { typeResults, name, visibleToOrganization } = params

  const data = {
    user_id: userId,
    name: name,
    visible_to_organization: visibleToOrganization,
    type_results: typeResults,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/processes_classifier_preset`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function getProcessesClassifierPresets(apiKey, userId, params = {}) {
  const { name, page, withOrganizationPresets } = params

  const queryString = objToQueryString({
    user_id: userId,
    name: name,
    with_organization_presets: withOrganizationPresets,
    page: page || 1,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/processes_classifier_preset?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function deleteProcessesClassifierPreset(apiKey, userId, params = {}) {
  const { presetId } = params

  const data = {
    user_id: userId,
    preset_id: presetId,
  }

  const res = await axios({
    method: 'DELETE',
    url: `${HOST}/processes_classifier_preset`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function postReprocessServicesWithError(apiKey, userId, panelEnv, params = {}) {
  const { docId } = params

  const data = {
    user_id: userId,
    doc_id: docId,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/${panelEnv}/reprocess/services_with_error`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}
