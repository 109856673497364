import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(_theme => ({
  dialogContainerContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: 16,
  },
  listServicesContainer: {
    alignSelf: 'start',
    marginTop: 10,
  },
}))

export default styles

