import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { ThemeProvider } from 'styled-components'

import { Pagination, Table } from 'ui/components'
import { getDailyProcessTimeAnalytics } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'
import { formatDate } from 'utils/masks'

import { renderItemValue } from '../commonFuncs'
import Container from './styles'

export default function ({ _location }) {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const [analyticsList, setAnalyticsList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = React.useState(false)

  const analyticsListDaily = async (options = {}) => {
    let res = null
    try {
      res = await getDailyProcessTimeAnalytics(
        apiKey,
        userId,
        panelEnv,
        {
          page: _.has(options, 'page') ? options.page : currentPage,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao listar analytics')
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
    setAnalyticsList(res.data)
    setLoading(false)
  }

  useEffect(() => {
    analyticsListDaily()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = async (event, page) => {
    const options = {
      page: page,
    }

    setLoading(true)
    analyticsListDaily(options)
  }

  const renderAnalyticsItems = (list) => {
    return _.map(list, (item, i) => {
      const analytics = item[`${panelEnv}_data`]
      const { total } = analytics

      const {
        average_time,
        qty_less_than_5_min,
        qty_less_than_10_min,
        qty_less_than_20_min,
        qty_less_than_30_min,
        qty_less_than_60_min,
        qty_less_than_120_min,
        qty_greater_than_120_min,
      } = analytics.process_time || {}

      return (
        <tr key={i.toString()}>
          <td>{formatDate(item.initial_date, { hideHour: true })}</td>
          <td>{total}</td>
          <td>{_.isNumber(average_time) ? _.ceil(average_time / 60) : '-'}</td>
          <td>{renderItemValue(qty_less_than_5_min, total)}</td>
          <td>{renderItemValue(qty_less_than_10_min, total)}</td>
          <td>{renderItemValue(qty_less_than_20_min, total)}</td>
          <td>{renderItemValue(qty_less_than_30_min, total)}</td>
          <td>{renderItemValue(qty_less_than_60_min, total)}</td>
          <td>{renderItemValue(qty_less_than_120_min, total)}</td>
          <td>{renderItemValue(qty_greater_than_120_min, total)}</td>
        </tr>
      )
    })
  }

  return (
    <ThemeProvider theme={{}}>
      <Container>
        <h1 className="title-container">Tempo das Consultas {panelEnv.toUpperCase()}</h1>

        <Table className="table">
          <thead>
            <tr>
              <th>Período</th>
              <th>Consultas</th>
              <th>Média (Mins)</th>
              <th>{'< 5 min'}</th>
              <th>{'< 10 min'}</th>
              <th>{'< 20 min'}</th>
              <th>{'< 30 min'}</th>
              <th>{'< 60 min'}</th>
              <th>{'< 120 min'}</th>
              <th>{'>= 120 min'}</th>
            </tr>
          </thead>

          <tbody>
            {loading || renderAnalyticsItems(analyticsList)}
          </tbody>
        </Table>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
        />
      </Container>
    </ThemeProvider>
  )
}
