import React from 'react'
import _ from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { Button } from 'ui/components'

export default ({
  open,
  onCancel,
  onConfirm,
  content,
  title,
  btnConfirmDisabled = false,
  btnCancelDisabled = false,
  btnConfirmText = 'Confirmar',
  btnCancelText = 'Fechar',
}) => {
  if (open !== true) return null

  const handleConfirm = (event) => {
    event.stopPropagation()

    if (onConfirm) onConfirm(event)
  }

  const handleCancel = (event) => {
    event.stopPropagation()

    if (onCancel) onCancel(event)
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-content"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      maxWidth={false}
      onClick={e => e.stopPropagation()}
    >
      <DialogTitle id="alert-dialog-title">
        <strong>{title}</strong>
      </DialogTitle>
      <DialogContent>
        {_.isString(content)
        ? <DialogContentText id="alert-dialog-content">{content}</DialogContentText>
        : content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" disabled={btnCancelDisabled} variant="text">
          {btnCancelText}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus disabled={btnConfirmDisabled}>
          {btnConfirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
