import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'

import Popover from '@material-ui/core/Popover'
import { SearchDoc, Table, DocListBasic } from 'ui/components'
import { getList } from 'services/apiMotorBgc'
import serviceStatusConstants from 'utils/constants/serviceStatus'
import reportError from 'utils/errorReporter'

import Container, { PopoverContainer } from './styles'

export default function ({ ...props }) {
  const history = useHistory()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const [consultsList, setConsultsList] = useState([])
  const [loading, setLoading] = useState(false)
  const [popoverAnchorEl, setAnchorEl] = useState(null)
  const searchEl = React.createRef()

  const closePopOver = () => {
    setConsultsList([])
    setAnchorEl(null)
  }

  const parseSearchResponse = (res) => {
    const resKey = `${panelEnv}_datas`

    setConsultsList(res.data[resKey])
  }

  const handleClick = async (value) => {
    if (_.isEmpty(value)) {
      toast.error('Termo para pesquisa inválido!', { position: 'top-center' })
      return
    }

    setLoading(true)

    setAnchorEl(searchEl.current)

    let res = null
    try {
      res = await getList(
        apiKey,
        userId,
        panelEnv,
        {
          docNumber: value,
          withServices: true,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao listar consultas')
      return
    }

    parseSearchResponse(res)
    setLoading(false)
  }

  const handleClose = () => {
    closePopOver()
  }

  const handleClickDoc = (doc) => {
    closePopOver()

    const date = moment(doc.created_at).format("DD-MM-YYYY")
    const queryString = `${panelEnv}=${doc[panelEnv]}&initial_date=${date}&final_date=${date}`

    switch (doc.status) {
      case serviceStatusConstants.SERVICE_STATUS.processing: {
        if (_.some(doc.service_infos, { 'slow_processing': true })) {
          history.push(`/${panelEnv}/pending?${queryString}`)
          return
        }

        history.push(`/${panelEnv}/${doc.status.toLowerCase()}`)
        return
      }
      case serviceStatusConstants.SERVICE_STATUS.error: {
        history.push(`/${panelEnv}/with-error?${queryString}`)
        return
      }
      default: {
        break
      }
    }

    history.push(`/${panelEnv}/${doc.analysis_status.toLowerCase()}?${queryString}`)
  }

  const open = Boolean(popoverAnchorEl)
  const id = open ? 'simple-popover' : null

  return (
    <Container>
      <SearchDoc
        ref={searchEl}
        aria-describedby={id}
        onSearch={(value) => handleClick(value)}
        type={panelEnv}
        {...props}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverContainer>
          <Table className="table">
            <thead>
              <tr>
                <th>Documento</th>
                <th>Data</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <DocListBasic
                docList={consultsList}
                loading={loading}
                onClickItem={handleClickDoc}
              />
            </tbody>
          </Table>
        </PopoverContainer>
      </Popover>
    </Container>
  )
}
