import jwtDecode from 'jwt-decode'
import _ from 'lodash'

import { reqS3PresignerUrl } from 'services/apiFilesDownloader'

export const isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response
}

export const extractOnlyNumbers = (docNumber) => {
  return docNumber.replace(/[^\d]/g, '')
}

export const isUserLogged = token => {
  if (_.isEmpty(token)) return false

  const decoded = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return decoded.exp > currentTime
}

export const sleep = m => new Promise(r => setTimeout(r, m))

export const getExtensionFileName = (fileName) => {
  const ext = fileName.split('.').pop()
  return ext.split(/[^0-9a-z-_]/i)[0]
}

export const s3PresignerUrlOpen = async (docUrl) => {
  if (!docUrl) return

  try {
    const response = await reqS3PresignerUrl(docUrl)

    window.open(response.data.url, '_blank')
  } catch (err) {
    if (err.response) {
      console.error(err.response)
    } else if (err.request) {
      console.error(err.request)
    } else {
      console.error(err.message)
    }
  }
}

export const objToQueryString = (objQueryString) => {
  return _
    .chain(objQueryString)
    .pickBy((query) => !(_.isNil(query) || query === ''))
    .map((v, k) => `${k}=${v}`)
    .join('&')
    .value()
}

export const objToString = (objString) => {
  return _
    .chain(objString)
    .map((v, k) => `${k}: ${v}`)
    .join(', ')
    .value()
}

export const translateBoolean = (bool, options = {}) => {
  switch (bool) {
    case true: return !_.isNil(options.textTrue) ? options.textTrue : 'SIM'
    case false: return !_.isNil(options.textFalse) ? options.textFalse : 'NAO'
    default: return !_.isNil(options.textDefault) ? options.textDefault : _.toString(bool)
  }
}

export const downloadFromUri = (url, fileName) => {
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const serviceIdToName = (PlanServicesServices, serviceId) => (
  _.chain(PlanServicesServices)
    .find({ service_id: serviceId })
    .get('name_short')
    .value() || serviceId
)

export const formatGovBrAccountInput = (account) => (
  { ...account, user_cpf: extractOnlyNumbers(account.user_cpf) }
)

/**
 * Finds and returns the duplicates found in a given array.
 * The array must not be of objects.
 * @param {*} array An array of primitive values
 * @returns The duplicates found
 */
export const findArrayDuplicates = (array) => {
  const arrayWithoutDuplicates = [...new Set(array)]

  let duplicates = [...array]
  arrayWithoutDuplicates.forEach((item) => {
    const i = duplicates.indexOf(item)
    duplicates = duplicates
      .slice(0, i)
      .concat(duplicates.slice(i + 1, duplicates.length))
  })

  return duplicates
}

/**
 * Convert a 2D array into a CSV blob, then to a URL object
 */
export const arrayToCsv = (data) => {
  const csvContent = data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double quotes
          .map((v) => `"${v}"`) // quote it
          .join(';'), // semicolon-separated
    )
    .join('\r\n') // rows starting on new lines

  // Create a blob
  const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  return URL.createObjectURL(csvBlob)
}
