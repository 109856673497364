import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Tooltip } from '@material-ui/core'
import { DialogConfirm, Icons } from 'ui/components'

import { formatDocNumber } from 'utils/masks'
import reportError from 'utils/errorReporter'
import { postReprocessServicesWithError } from 'services/apiMotorBgc'

import { serviceIdToName } from 'utils/functions'
import serviceStatusConstants from 'utils/constants/serviceStatus'
import { PANEL_ENVS } from 'utils/constants/environments'

import styles from './styles'

export default ({ doc, onSuccess }) => {
  const classes = styles()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  let planServicesServices = null
  switch (panelEnv) {
    case PANEL_ENVS.CPF:
      planServicesServices = useSelector((state) => state.planCpfServices.services)
      break
    case PANEL_ENVS.CNPJ:
      planServicesServices = useSelector((state) => state.planCnpjServices.services)
      break
    default:
  }

  const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false)

  const postRequest = async () => {
    try {
      await postReprocessServicesWithError(
        apiKey,
        userId,
        panelEnv,
        {
          docId: doc.doc_id,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao reprocessar serviços')
      return
    }

    setDialogConfirmOpen(false)
    onSuccess()
    toast.success('Os serviços com erro estão sendo reprocessados')
  }

  const handleClickAction = (event) => {
    event.stopPropagation()

    setDialogConfirmOpen(true)
  }

  const handleConfirm = () => {
    postRequest()
  }

  const handleCancel = () => {
    setDialogConfirmOpen(false)
  }

  const renderTooltipText = () => (
    `Reprocessar serviços com erro`
  )

  const renderListServices = () => {
    const servicesError = _.filter(doc.service_infos, { status: serviceStatusConstants.SERVICE_STATUS.error })

    const servicesElem = (
      _.map(servicesError, (service) => (
        <li key={service.data_id}>{serviceIdToName(planServicesServices, service.service_id)}</li>
      ))
    )

    return (
      <div className={classes.listServicesContainer}>
        <p>Serviços que serão reprocessados:</p>
        <ul>
          {servicesElem}
        </ul>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Tooltip title={renderTooltipText()}>
        <div
          className="icon-button"
          onClick={(e) => handleClickAction(e)}
        >
          <Icons icon="refresh" />
        </div>
      </Tooltip>

      <DialogConfirm
        open={dialogConfirmOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title="Reprocessar serviços com Erro"
        content={(
          <div className={classes.dialogContainerContent}>
            <div>
              <span>{`${panelEnv.toUpperCase()} `}</span>
              <strong>{formatDocNumber(doc[panelEnv], panelEnv)}</strong>
              <span>, Tem certeza que deseja reprocessar os serviços que deram erro?</span>
            </div>

            {renderListServices()}
          </div>
        )}
      />
    </React.Fragment>
  )
}
