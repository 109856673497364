import _ from 'lodash'
import {
  formatToCPF as formatCPF,
  formatToCNPJ as formatCNPJ,
} from 'brazilian-values'
import moment from 'moment'

import { cpfValid } from 'utils/validators'

export const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

export const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

export const phoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const cepMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
]

export const dateMask = [
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const formatToCPF = (cpf) => {
  return cpf ? formatCPF(cpf) : null
}

export const formatToCNPJ = (cnpj) => {
  return cnpj ? formatCNPJ(cnpj) : null
}

export const formatDocNumber = (docNumber, type) => {
  switch (type) {
    case 'cpf': return formatToCPF(docNumber)
    case 'cnpj': return formatToCNPJ(docNumber)
    default: return cpfValid(docNumber) ? formatToCPF(docNumber) : formatToCNPJ(docNumber)
  }
}

export const formatDate = (date, options = {}) => {
  if (_.isEmpty(date)) return ''

  const { hideDate, hideHour } = options

  const formatText = []
  if (hideDate !== true) formatText.push('DD/MM/YYYY')
  if (hideHour !== true) formatText.push('HH:mm:ss')

  return moment(date).format(formatText.join(' '))
}
